import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ErrorPage = ({ statusCode }) => {
  const { t } = useTranslation();
  return (
    <div className="error">
      <a className="error-logo" href="/">
        bina.az
      </a>
      <p className="error-status">{statusCode}</p>
      <p className="error-text">{t('pages.error.error_text')}</p>
      <a className="error-link" href="/">
        {t('pages.error.link_to_main')}
      </a>
    </div>
  );
};

ErrorPage.propTypes = {
  statusCode: PropTypes.number
};

export default ErrorPage;
